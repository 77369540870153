import '../App.css';
import Carousel from '../components/Carousel';
import Marketing from '../components/Marketing';
import Contact from '../components/Contact';
import Navbar from '../components/Navbar';

export const Home = () => {
  return (
    <div className="App animated fadeIn">
      <header data-bs-theme="dark">
        <Navbar/>
      </header>
      <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <Carousel/>
      </div>
      <div className="container marketing">
        <Marketing/>
      </div>
      <div id="contacto" className="contact-section">
        <Contact />
      </div>
    </div>
  );
}

export default Home;

