
import { Navigate, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from "react";
import ScrollToHashElemet from '../components/ScrollToHashElement';
import ScrollToTop from '../components/ScrollToTop';
import Loading from '../components/Loading'
import Wa from '../components/wa';
import Home from '../pages/home';
import Footer from '../components/Footer';
import Terms from '../pages/terms'
import ErrorPage from '../pages/error-page'
import PrivacyPolicy from '../pages/PrivacyPolicy';
import '../App.css';
    
export const AppRouter = () => {

  const [loading, setLoading] = useState(true);
  useEffect(() => {
  setTimeout(() => setLoading(false), 250);
  }, [])
  if (loading) { 
    return <Loading />
  }
  
  return (
  <>
    <ScrollToTop />
    <ScrollToHashElemet/>
    <Wa/>
        <Routes>
            <Route path="terms" element={<Terms />} errorElement={<ErrorPage/>}/> 
            <Route path="privacy-Policy" element={<PrivacyPolicy />} errorElement={<ErrorPage/>}/>
            <Route path="/" element={<Home />} errorElement={<ErrorPage/>}/>
            <Route path="/*" element={<Navigate to="/"/>}/>
        </Routes>
    <footer className="container">
        <Footer/>
    </footer>
  </>
  )
}
        
    export default AppRouter;


