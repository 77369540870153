const Loading = () => {
  return (
    <div className="loader-container">
      <div className="d-flex justify-content-center">
          <div className="spinner-border text-secondary" style={{ width: '200px', height: '200px' }} role="status"></div>
      </div>
    </div>
  )
}

export default Loading;