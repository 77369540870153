import btc from '../images/btc-bgnd.png';

export const Carousel = () => {
  return (
    <>
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div className="carousel-inner">
      <div className="carousel-item active carousel-item-1 bd-placeholder-img">
        <div className="container">
          <div className='btc-carousel'>
            <img src={btc} className='img-fluid' alt="btc"/>
          </div>
          <div className="carousel-caption text-start">
            <h1>¡Welcome to Crypstore!</h1>
            <p className="opacity-75">Your trusted digital exchange in El Salvador</p>
          </div>
        </div>
      </div>
      <div className="carousel-item carousel-item-2 bd-placeholder-img">
        <div className="container">
          <div className="carousel-caption">
            <h1>What is our Speciality?</h1>
            <p>It's to provide a secure and efficient service to exchange your Bitcoins and USD.</p>
          </div>
        </div>
      </div>
      <div className="carousel-item carousel-item-3 bd-placeholder-img">
        <div className="container">
          <div className="carousel-caption text-start">
            <h1>El Salvador</h1>
            <p>A pioneering country that has adopted Bitcoin as legal tender.</p>
          </div>
        </div>
      </div>
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">back</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">next</span>
    </button>
    </>
  );
}

export default Carousel;