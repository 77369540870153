// import { Link } from 'react-router-dom';

export const Contact = () => {
  return (
    <>
    <h2>Contact</h2>
    <form className="contact-form" action="https://formspree.io/f/xzblnpvo" method="POST">
        <div className="form-group">
            <label htmlFor="exampleInputEmail1">Name</label>
            <input name="nombre" type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required/>
        </div>
        <div className="form-group">
            <label className="label-color" htmlFor="exampleInputEmail2">Email</label>
            <input name="email" type="email" className="form-control" id="exampleInputEmail2" aria-describedby="emailHelp" required/>
        </div>
        <div className="form-group">
            <label className="label-color" htmlFor="exampleFormControlSelect1">Subject</label>
            <select name="asunto" className="form-control" id="exampleFormControlSelect1" required>
                <option defaultValue>Select an option</option>
                <option value="Inquiries">Inquiries</option>
                <option value="Complaints">complaints</option>
                <option value="Coments">Coments</option>
            </select>
        </div>
        <div className="form-group">
            <label className="label-color" htmlFor="exampleFormControlTextarea1">Message</label>
            <textarea name="mensaje" className="form-control" id="exampleFormControlTextarea1" rows="3" required></textarea>
        </div>
        <button type="submit" className="btn btn-primary contact-button">Send</button>
    </form>
    
    <p>*Complaints with the SSF  <a rel="noreferrer" target="_blank" className="label-color" href='https://ssf.gob.sv/atencion-al-publico/'>tap here</a></p>
    </>
  )
}

export default Contact;
