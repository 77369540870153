import { Link, NavLink } from 'react-router-dom';
import btcImg from '../images/bitcoin.jpg';

export const Navbar = () => {
    return (
        <nav id="top" className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img className="d-inline-block align-top" width="40" height="30" src={btcImg} alt="..."/>
                    Crypstore SV
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">
                        <li className="nav-item">
                            <NavLink className={ ({isPending}) => `nav-link  ${ isPending ? 'active':'' }` } to="/#nosotros">About us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={ ({isPending}) => `nav-link  ${ isPending ? 'active':'' }` } to="/#servicios">Services</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={ ({isPending}) => `nav-link  ${ isPending ? 'active':'' }` } to="/#contacto">Contact</NavLink>
                        </li>

                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="index.html" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="index.html">Action</a>
                                <a className="dropdown-item" href="index.html">Another action</a>
                            </div>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;