import roundImg1 from '../images/Img1.jpg';
import roundImg2 from '../images/Img2.jpg';
import roundImg3 from '../images/Img3.jpg';
import imgBig1 from '../images/img21.jpg';
import imgBig2 from '../images/img22.jpg';
import imgBig3 from '../images/img23.jpg';


export const Marketing = () => {
  return(
    <>
      <div id="nosotros" className="row us">
        <div className="col-lg-12">
        <center><h2 className="us-heading fw-normal lh-4">About us</h2></center>
        <p className="lead">At Crypstore S.A. de C.V., our goal is to provide foreign investors and local users with a safe and reliable way to buy, sell and use Bitcoin as a form of payment in local businesses. We strive to make cryptocurrency accessible in a simple and transparent way, offering competitive rates and exceptional customer service. We work to provide a seamless experience and promote the adoption of Bitcoin as a global financial solution.</p>
        </div>
      </div>
      <hr className="featurette-divider"/>
      <div id="servicios" className="row servicios">
        <div className="col-lg-4">
          <img src={roundImg1} className="bd-placeholder-img rounded-circle" width="140" height="140" aria-label="Placeholder"/>
          <h2 className="fw-normal">Transparent Rates</h2>
          <p>We believe in transparency. We detail our exchange fees so you know exactly what you are getting on every transaction, with no hidden surprises.</p>
        </div>
        <div className="col-lg-4">
          <img src={roundImg2} className="bd-placeholder-img rounded-circle" width="140" height="140" aria-label="Placeholder"/>
          <h2 className="fw-normal">Flexible Limits</h2>
          <p>We adapt our transaction limits to meet your individual needs, whether you are making small or large trades.</p>
        </div>
        <div className="col-lg-4">
          <img src={roundImg3} className="bd-placeholder-img rounded-circle" width="140" height="140" aria-label="Placeholder"/>
          <h2 className="fw-normal">Professional Support</h2>
          <p>Our support team is available to assist you in every step of the process. We resolve your doubts and guarantee a hassle-free experience.</p>
        </div>
      </div>
      <hr className="featurette-divider"/>
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">Vanguard <span className="text-body-secondary">Security</span></h2>
          <p className="lead">We implement the latest security measures to protect your transactions and personal data, giving you peace of mind in every transaction</p>
        </div>
        <div className="col-md-5">
          <img src={imgBig1} alt="Imagen1" className="rounded bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"/>
        </div>
      </div>
      <hr className="featurette-divider"/>
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1">Protection <span className="text-body-secondary">of Sensitive Data</span></h2>
          <p className="lead">At Crypstore, we guarantee the protection of your personal data during each exchange. We use strong security protocols to ensure the confidentiality of information</p>
        </div>
        <div className="col-md-5 order-md-1">
          <img src={imgBig2} alt="Imagen2" className="rounded bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"/>
        </div>
      </div>
      <hr className="featurette-divider"/>
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1">Rigorous <span className="text-body-secondary">Verification</span></h2>
          <p className="lead">We implement a strict verification process for all transactions, ensuring the authenticity and security of each currency exchange</p>
        </div>
        <div className="col-md-5">
          <img src={imgBig3} alt="Imagen3" className="rounded bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"/>
        </div>
      </div>
      <hr className="featurette-divider"/>
      <div className="alert alert-success alert-protection" role="alert">
        Active Protection: Our team is committed to continually monitoring and updating our security measures in order to protect your information throughout the exchange process
      </div>
    </>
  );
}

export default Marketing;