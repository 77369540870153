import { Link } from "react-router-dom";
import NavbarSimple from "../components/NavbarSimple";

export const PrivacyPolicy = () => {
  return (
    <>
    <header data-bs-theme="dark">
        <NavbarSimple/>
    </header>
    <div className="container animated fadeIn">
    <Link to="/"><button type="button" className="btn btn-secondary mt-5"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
    </svg> Regresar </button></Link>
        <h1 className="display-1 text-center mt-5">Privacy Policy</h1>
        <hr className="featurette-divider"/>
        <p  className="lead">This Privacy Policy describes how Crypstore, S. A. de C. V. collects, uses, and protects the personal
        information that the user provides through email after contacting us and accepting our terms and
        conditions to acquire our services on our website [crypstoresv.com].</p>
        <hr className="featurette-divider"/>
        <h3>Collection of Personal Information</h3><br/>
        <ul>
            <li><h5>Request for information:</h5>
                <p className="lead">We may request personal information, such as name, address, phone
                number, email address, and Bitcoin wallet address, after you contact us and agree to our terms and
                conditions to acquire our services.</p>
            </li>
            <li><h5>Collection method:</h5>
                <p className="lead">This information will be requested via email after the user has agreed to acquire
                our services and has accepted our terms and conditions.</p>
            </li>
        </ul>
        <hr className="featurette-divider"/>
        <h3>Use of Information</h3><br />
        <ul>
            <li><h5>Purpose of use:</h5>
                <p className="lead">
                The collected personal information will be used solely to provide our services, verify
                your identity, process transactions, communicate with you, and comply with legal and regulatory
                obligations.
                </p>
            </li>
            <li><h5>Disclosure to third parties:</h5>
                <p className="lead">
                We are committed to not sharing, selling, or renting your personal
                information to third parties without your consent, except when necessary to comply with legal
                requirements.
                </p>
            </li>
        </ul>
        <hr className="featurette-divider"/>    
        <h3>Information Security</h3><br />
        <ul>
            <li><h5>Security measures:</h5>
                <p className="lead">
                We implement technical and organizational security measures to protect your
                personal information against unauthorized access, disclosure, alteration, or destruction.
                </p>
            </li>
        </ul>
        <hr className="featurette-divider"/>
        <h3>Changes to the Privacy Policy</h3><br />
        <ul>
            <li><h5>Updates:</h5>
                <p className="lead">
                We reserve the right to update this Privacy Policy at any time. Any changes will be notified
                via email to our registered users and will be considered effective upon publication.
                </p>
            </li>
        </ul>
        <hr className="featurette-divider"/>
        <h3>Consent</h3><br />
        <p className="lead">
        By accepting our terms and conditions to acquire our services, you consent to the processing of your
        personal information in accordance with this Privacy Policy.
        </p>
        <hr className="featurette-divider"/>
        <h3>Contact</h3><br />
        <p className="lead">
        If you have any questions about this Privacy Policy, please contact us at [contact@crypstoresv.com].
        </p>
    </div>
    </>
    )
}

export default PrivacyPolicy;