import { Link } from "react-router-dom";
import NavbarSimple from "../components/NavbarSimple";

export const Terms = () => {
  return (
    <>
    <header data-bs-theme="dark">
      <NavbarSimple/>
    </header>
    <div className="container animated fadeIn">
    <Link to="/"><button type="button" className="btn btn-secondary mt-5"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
    </svg> Regresar </button></Link>
      <h1 className="display-1 text-center mt-5">Terms & Conditions</h1>
      <hr className="featurette-divider"/>
      <h3>1. Introduction </h3> <br/>
      <p className="lead">These terms and conditions govern the use of the website crypstoresv.com and any service offered by
      Crypstore, S. A. de C. V. By accessing or using our website, you agree to fully comply with these terms and
      conditions. These terms are binding and govern all interactions between users and Crypstore.
      By using our website, you acknowledge that you have read, understood, and agreed to these terms and
      conditions in their entirety. If you do not agree with any part of these terms, it is recommended that you
      refrain from using our website and services.</p>
      <hr className="featurette-divider"/>
      <h3>2. Use of the Site</h3> <br/>
      <ol type="A">
        <li><h5>Acceptance of Terms of Use</h5>
          <p className="lead">By using our website crypstoresv.com or any service offered by Crypstore, you agree to comply with these
          terms and conditions.</p>
        </li>
        <li><h5>Authorized Use</h5>
          <p className="lead">Users have the right to access and use the services provided by Crypstore solely for the purpose of acquiring
          Bitcoin and related transactions. Any other use of the website, including but not limited to illegal activities, is
          strictly prohibited.</p>
        </li>
        <li><h5>User Conduct</h5>
          <p className="lead">Users agree not to engage in activities that may harm, interfere with, or disrupt the normal operation of the
          website or the services provided. This includes, among other things, attempting to access restricted areas,
          collecting information from other users without authorization, or taking any action that violates the site's
          security.</p>
        </li>
        <li><h5>Use of Information and Content</h5>
          <p className="lead">The content provided on the website is for informational purposes only. The accuracy, timeliness, or
          completeness of the information provided is not guaranteed, and users are responsible for any action taken
          based on such information.</p>
        </li>
        <li><h5>Restricted Access</h5>
          <p className="lead">We reserve the right to restrict access to certain areas or features of the website, or even suspend or cancel a
          user's access without prior notice if we believe the user has violated these terms and conditions.</p>
        </li>
      </ol>
      <hr className="featurette-divider"/>
      <h3>3. Privacy </h3><br/>
      <ol type="A">
      <li><h5>Protection of Personal Data</h5>
        <p className="lead">Crypstore values and respects your privacy. We are committed to protecting the personal data you share with
        us in accordance with applicable privacy and data protection laws.</p>
      </li>
      <li><h5>Collection of Information</h5>
        <p className="lead">By using our services, we may collect certain personal information such as name, address, phone number,
        email address, and Bitcoin wallet details. This information will be obtained legally and with your explicit
        consent to enhance your user experience and facilitate our operations.</p>
      </li>
      <li><h5>Use of Information</h5>
        <p className="lead">The collected personal information will be used only for specific purposes described in our Privacy Policy. This
        may include identity verification, service provision, communication with users, and compliance with legal and
        regulatory obligations.</p>
      </li>
      <li><h5>Disclosure to Third Parties</h5>
        <p className="lead">We are committed to not sharing, selling, or renting your personal information to third parties without your
        consent, except when necessary to comply with legal requirements.</p>
      </li>
      <li><h5>Data Security</h5>
        <p className="lead">We implement appropriate technical and organizational security measures to protect your personal data
        against unauthorized access, disclosure, alteration, or destruction.</p>
      </li>
      <li><h5>Acceptance</h5>
        <p className="lead">By using our services, you agree to the processing of your personal information in accordance with our Privacy
        Policy.</p>
      </li>
      </ol>
      <hr className="featurette-divider"/>
      <h3>4. Intellectual Property </h3> <br/>
      <ol type="A">
        <li><h5>Property Rights</h5>
          <p className="lead">All intellectual property rights related to our website, including but not limited to copyrights, trademarks, trade
          names, logos, graphics, content, software, and any other material, are the exclusive property of Crypstore, S. A.
          de C. V., unless otherwise stated.</p>
        </li>
        <li><h5>Permitted Use</h5>
          <p className="lead">Users may access and use the content provided on our website solely for informational and service acquisition
          purposes. Any other use, including reproduction, distribution, modification, or sale, is strictly prohibited
          without the express written authorization of Crypstore.</p>
        </li>
        <li><h5>Restrictions</h5>
        <p className="lead">Unauthorized use or exploitation of the content protected by intellectual property rights belonging to
        Crypstore for any purpose not expressly permitted by these terms and conditions is prohibited.</p>
        </li>
        <li><h5>Reports of Infringement</h5>
          <p className="lead">Crypstore respects the intellectual property rights of third parties. If you believe that your intellectual property
          has been used in a way that constitutes a violation of your rights, please notify us immediately at
          contact@crypstoresv.com to take appropriate action.</p>
        </li>
        <li><h5>Disclaimer</h5>
          <p className="lead">Crypstore does not assume responsibility for the misuse or unauthorized use of third-party intellectual
          property by users of our website.</p>
        </li>
      </ol>
      <hr className="featurette-divider"/>
      <h3>5. Communications</h3><br/>
      <ol type="A">
        <li><h5>Occasional Offers</h5>
          <p className="lead">Crypstore, S. A. de C. V., may occasionally offer promotions to users who have contacted the company. These
          communications may include information about discounts, special promotions, or updates about our services.
          Such offers will be made only in response to user contact requests and will occasionally be sent through the
          provided contact medium.</p>
        </li>
        <li><h5>Notification of Updates</h5>
          <p className="lead">In the case of updates to the Terms and Conditions or the Privacy Policy, Crypstore undertakes to send an email
          to registered users on our website to inform them of such changes. However, it is the responsibility of the user
          to review and accept these changes.</p>
        </li>
        <li><h5>Privacy Respect</h5>
          <p className="lead">We are committed to respecting the privacy of our users and not engaging in unsolicited or excessive
          commercial communications.</p>
        </li>
      </ol>
      <hr className="featurette-divider"/>
      <h3>6. Responsibilities and Limitations</h3><br/>
      <ol type="A">
        <li><h5>Use under Responsibility</h5></li>
          <p className="lead">The use of the website crypstoresv.com and the services offered by Crypstore are under the sole responsibility
          of the user. The company is not responsible for any direct, indirect, incidental, special, consequential, or
          punitive damages arising from the use or inability to use our services.</p>
        <li><h5> Informational Content</h5></li>
          <p className="lead">Crypstore provides information on the website for informational purposes only. We do not guarantee the
          accuracy, timeliness, or completeness of the content. Users use the provided information at their own risk and
          responsibility.</p>
        <li><h5>Limitation of Liability</h5></li> 
          <p className="lead">To the maximum extent permitted by law, Crypstore, S. A. de C. V., and its affiliates shall not be liable to users
          for any damages or losses arising from the use or inability to use our services, even if informed about the
          possibility of such damages.</p>
        <li><h5> Legal Compliance</h5></li>
          <p className="lead">Users agree to comply with all applicable laws and regulations when using our website and services. Crypstore
          is not responsible for any illegal or unauthorized actions by users.</p>
      </ol>
      <hr className="featurette-divider"/>
      <h3>7. Modifications to the Terms and Conditions</h3><br/>
      <ol type="A">
        <li><h5>Updates and Revisions</h5>
          <p className="lead">Crypstore reserves the right to modify, update, or revise these terms and conditions at any time and without
          prior notice. Crypstore is responsible to send an email to registered users on our website to inform them of
          such changes. However, it is the responsability of the user to review and accept these changes. Changes will be
          effective immediately upon posting on the website crypstoresv.com.</p>
        </li>
        <li><h5>Continued Use</h5>
          <p className="lead">The continued use of the website after the implementation of changes in the terms and conditions shall be
          deemed as acceptance of the same. If a user does not agree with the modifications, it is recommended to
          refrain from using the website and services</p>
        </li>
      </ol>
      <hr className="featurette-divider"/>
      <h3>8. Applicable Law </h3><br/>
      <ol type="A">
        <li><h5>Jurisdiction and Applicable Law for International Users</h5>
          <p className="lead">These terms and conditions are governed by the laws of the Republic of El Salvador. However, in the case of
          international users, any dispute, controversy, or claim arising from these terms and conditions, including their
          validity, interpretation, breach, or termination, may be resolved through arbitration or mediation agreed upon
          by the parties, seeking an amicable solution before resorting to the exclusive jurisdiction of Salvadoran courts.</p>
        </li>
        <li><h5>Agreement on Dispute Resolution</h5>
          <p className="lead">By using our website and services, international users agree to resolve any dispute or claim related to these
          terms and conditions through alternative dispute resolution methods, such as arbitration or mediation, before
          resorting to the jurisdiction of the courts in El Salvador.</p>
        </li>
        <li><h5>Application of Local Laws and Consumer Protection</h5> 
          <p className="lead">Provisions and consumer rights under the local laws of the country of residence of the international user shall
          prevail to the extent that they offer greater protection than the rights established in these terms and
          conditions.</p>
        </li>
      </ol>
      <hr className="featurette-divider"/>
      <h3>9. Fees and Transactionality Terms</h3><br/>
      <p className="lead">Below, we detail the commissions associated with different ranges of purchase prices available with Crypstore.
      The fees are presented in terms of percentage and in dollars, offering transparency about the costs for each
      transaction, with the aim of providing clarity and understanding to our users about the applicable charges
      based on the amount of their purchase.</p>
      <table className="table table-dark table-striped mt-5">
        <thead>
          <tr>
            <th scope="col">Purchase Price (USD)</th>
            <th scope="col">Commission (USD)</th>
            <th scope="col">Commission (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">$50.00</th>
            <td>$7.50</td>
            <td>15.00%</td>
          </tr>
          <tr>
            <th scope="row">$150.00</th>
            <td>$18.50</td>
            <td>12.00%</td>             
          </tr>
          <tr>
            <th scope="row">$250.00</th>
            <td>$27.50</td>
            <td>10.80%</td>
          </tr>
          <tr>
            <th scope="row">$350.00</th>
            <td>$34.00</td>
            <td>9.71%</td>           
          </tr>
          <tr>
            <th scope="row">$500.00</th>
            <td>$48.00</td>
            <td>9.60%</td>
          </tr>
          <tr>
            <th scope="row">$650.00</th>
            <td>$60.00</td>
            <td>9.23%</td>
          </tr>
          <tr>
            <th scope="row">$800.00</th>
            <td>$70.40</td>
            <td>8.80%</td>
          </tr>
          <tr>
            <th scope="row">$1000.00</th>
            <td>$80.00</td>
            <td>8.00%</td>
          </tr>
        </tbody>
      </table>
      <hr className="featurette-divider"/>
      <h3>10. Contact</h3>
      <p>If you have any questions about these terms and conditions, please contact us at <strong>contact@crypstoresv.com</strong>.</p>
    </div>
    </>
  )
}

export default Terms;
