import {Link} from 'react-router-dom';
import ssf from '../images/ssf.png';
import bcr from '../images/bcr.png';
import esa from '../images/esa.webp';

export const Footer = () => {
    return(
        <>
        <div className="container mt-5">
            <div className="row">
                <div className="col text-center">
                <b><p className='txt-ssf-up'>SUPERVISED BY:</p></b>
                    <img src={ssf} alt="ssf" className='img-ssf bd-placeholder-img' height="50"/>
                    <b><p className='txt-ssf'>SUPERINTENDECIA <br/>DEL SISTEMA FINANCIERO</p></b>
                </div>
                <div className="col text-center">
                <b><p className='txt-ssf'>APPROVED BY:</p></b>
                    <img src={bcr} alt="bcr" className='img-bcr bd-placeholder-img' width="80" height="80"/>
                </div>
                <div className="col text-center">
                    <img src={esa} alt="esa" className='img-esa bd-placeholder-img' width="80" height="80"/>
                    <b><p className='txt-ssf'>EL SALVADOR</p></b>
                </div>
            </div>
        </div>
        <div className="mt-3">
            <a href="#"><button type="button" className="btn btn-secondary float-end">Back to top 
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
            </svg></button></a>
            <p>&copy; 2024 Crypstore S.A. de C.V. &middot; All rights reserved &middot; <Link to="/terms">Terms & Conditions</Link> &middot; <Link to="/privacy-policy">Privacy Policy</Link></p>
        </div>
        </>
    );
}

export default Footer;




