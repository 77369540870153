import { Link } from 'react-router-dom';
import btcImg from '../images/bitcoin.jpg';

export const NavbarSimple = () => {
    return (
        <nav id="top" className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img className="d-inline-block align-top" width="40" height="30" src={btcImg} alt="..."/>
                    Crypstore SV
                </Link>
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="index.html" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="index.html">Action</a>
                                <a className="dropdown-item" href="index.html">Another action</a>
                            </div>
                        </li> */}
            </div>
        </nav>
    );
}

export default NavbarSimple;